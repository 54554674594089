import React, { useState, useEffect, useRef } from "react";
import "./App.css";

function RC4(key) {
  const S = Array(256)
    .fill()
    .map((_, i) => i);
  let j = 0;
  for (let i = 0; i < 256; i++) {
    j = (j + S[i] + key.charCodeAt(i % key.length)) % 256;
    [S[i], S[j]] = [S[j], S[i]];
  }
  return S;
}

function encryptRC4(input, key) {
  const S = RC4(key);
  let i = 0,
    j = 0;
  return input
    .split("")
    .map((char) => {
      i = (i + 1) % 256;
      j = (j + S[i]) % 256;
      [S[i], S[j]] = [S[j], S[i]];
      return String.fromCharCode(char.charCodeAt(0) ^ S[(S[i] + S[j]) % 256]);
    })
    .join("");
}

function decryptRC4(input, key) {
  return encryptRC4(input, key); // RC4 加密和解密使用相同的过程
}

// 将日期字符串转换为时间戳（毫秒）
function dateStringToTimestamp(dateString) {
  return new Date(dateString).getTime();
}

// 将时间戳（毫秒）转换为日期字符串（YYYY-MM-DDTHH:mm 格式，用于datetime-local输入）
function timestampToDateString(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hour}:${minute}`;
}

// 格式化时间显示（YYYY 年 MM 月 DD 日 HH:mm:ss 格式）
function formatDateTime(timestamp) {
  if (!timestamp || isNaN(timestamp)) {
    return "无效日期";
  }
  try {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    //const second = date.getSeconds().toString().padStart(2, '0');
    return `${year}年${month}月${day}日 ${hour}:${minute}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "日期格式错误";
  }
}

function calculateWorkingHours(startTime, endTime) {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const diffInHours = (end - start) / (1000 * 3600);
  const fullDays = Math.floor(diffInHours / 24);
  const remainingHours = diffInHours % 24;

  let workingHours = fullDays * 8; // 每个完整的天算作 8 小时工作时间

  // 处理不足一天的时间
  if (remainingHours > 0) {
    if (remainingHours <= 8) {
      // 如果剩余时间不超过 8 小时，直接加上
      workingHours += remainingHours;
    } else {
      // 如果剩余时间超过 8 小时，按一个工作日（8小时）计算
      workingHours += 8;
    }
  }

  return workingHours;
}

function App() {
  const [overtimeRecords, setOvertimeRecords] = useState(() => {
    const storedOvertimeRecords = localStorage.getItem("overtimeRecords");
    if (storedOvertimeRecords) {
      try {
        const parsedData = JSON.parse(storedOvertimeRecords);
        // 验证并修复时间戳
        const validatedData = parsedData.map(record => ({
          ...record,
          startTime: Number(record.startTime) || null,
          endTime: Number(record.endTime) || null,
        }));
        return validatedData;
      } catch (error) {
        console.error("Error parsing overtimeRecords:", error);
        return [];
      }
    }
    return [];
  });

  const [consumptionRecords, setConsumptionRecords] = useState(() => {
    const storedConsumptionRecords = localStorage.getItem("consumptionRecords");
    if (storedConsumptionRecords) {
      try {
        const parsedData = JSON.parse(storedConsumptionRecords);
        // 验证并修复时间戳
        const validatedData = parsedData.map(record => ({
          ...record,
          consumptionStartTime: Number(record.consumptionStartTime) || null,
          consumptionEndTime: Number(record.consumptionEndTime) || null,
        }));
        return validatedData;
      } catch (error) {
        console.error("Error parsing consumptionRecords:", error);
        return [];
      }
    }
    return [];
  });

  const [restRecords, setRestRecords] = useState(() => {
    const storedRestRecords = localStorage.getItem("restRecords");
    if (storedRestRecords) {
      try {
        const parsedData = JSON.parse(storedRestRecords);
        // 验证并修复时间戳
        const validatedData = parsedData.map(record => ({
          ...record,
          txbegintime: Number(record.txbegintime) || null,
          txendtime: Number(record.txendtime) || null,
        }));
        return validatedData;
      } catch (error) {
        console.error("Error parsing restRecords:", error);
        return [];
      }
    }
    return [];
  });

  const [expandedRecord, setExpandedRecord] = useState(null);
  const [editRecord, setEditRecord] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const fileInputRef = useRef(null);

  const [showUpdateLog, setShowUpdateLog] = useState(false);

  // 更新记录数据
  const updateLog = [
    { date: "2023-08-29", content: "增加了年假的设定，用于计算年假的扣除，年假自动以 8h/天(整天)计算扣除，并且独立计算，当加班时间不足的时候可自动消耗年假扣除" },
    { date: "2023-08-27", content: "修正了时间计算逻辑，删除摆烂记录时时间自动归还对应的加班记录，当摆烂时间涉及跨越多个牛马时间时，自动从牛马记录中的开始时间进行对应的计算，摆烂时间按照 8 小时工作日计算并且自动跳过标准休息时间（周末），文件保存时使用 RC4 加密，记录在 localstorage 中，并可以以实体「牛马」文件保存在地" },
    { date: "2023-08-10", content: "修正了时间计算和跨天计算的逻辑" },
    { date: "2023-07-10", content: "修复了时间计算的bug，使用全局标准时间" },
    { date: "2024-07-01", content: "初始版本发布" },
    // 可以继续添加更多更新记录
  ];

  // 新增年假相关的 state
  const [annualLeave, setAnnualLeave] = useState(() => {
    const storedAnnualLeave = localStorage.getItem("annualLeave");
    return storedAnnualLeave ? JSON.parse(storedAnnualLeave) : { days: 0, remainingHours: 0 };
  });

  // 新增年假记录的 state
  const [annualLeaveRecords, setAnnualLeaveRecords] = useState(() => {
    const storedAnnualLeaveRecords = localStorage.getItem("annualLeaveRecords");
    return storedAnnualLeaveRecords ? JSON.parse(storedAnnualLeaveRecords) : [];
  });

  useEffect(() => {
    localStorage.setItem("overtimeRecords", JSON.stringify(overtimeRecords));
  }, [overtimeRecords]);

  useEffect(() => {
    localStorage.setItem(
      "consumptionRecords",
      JSON.stringify(consumptionRecords)
    );
  }, [consumptionRecords]);

  useEffect(() => {
    localStorage.setItem("restRecords", JSON.stringify(restRecords));
  }, [restRecords]);

  // 新增年假相关的 useEffect
  useEffect(() => {
    localStorage.setItem("annualLeave", JSON.stringify(annualLeave));
  }, [annualLeave]);

  useEffect(() => {
    localStorage.setItem("annualLeaveRecords", JSON.stringify(annualLeaveRecords));
  }, [annualLeaveRecords]);

  const handleOvertimeRecord = (record) => {
    const startTimestamp = new Date(record.startTime).getTime();
    const endTimestamp = new Date(record.endTime).getTime();
    const duration = (endTimestamp - startTimestamp) / (1000 * 3600); // 直接计算小时差
    const newRecord = {
      ...record,
      id: Date.now(),
      startTime: startTimestamp,
      endTime: endTimestamp,
      duration: duration,
      remainingTime: duration,
    };
    console.log("新的牛马记录:", newRecord);
    setOvertimeRecords([...overtimeRecords, newRecord]);
  };

  const handleRestRecord = (record) => {
    const startTimestamp = new Date(record.txbegintime).getTime();
    const endTimestamp = new Date(record.txendtime).getTime();
    const restDuration = calculateWorkingHours(startTimestamp, endTimestamp);
    console.log("请求的摆烂时:", restDuration);

    const totalAvailableOvertime = overtimeRecords.reduce(
      (total, record) => total + record.remainingTime,
      0
    );
    console.log("总可用牛马时��:", totalAvailableOvertime);

    if (restDuration > totalAvailableOvertime + annualLeave.remainingHours) {
      alert("剩余牛马时间和年假时间不足以申请这么多摆烂时间！");
      return;
    }

    let remainingRestTime = restDuration;
    let updatedOvertimeRecords = [...overtimeRecords];
    let newConsumptions = [];
    let annualLeaveUsed = 0;

    // 先使用加班时间
    for (let i = 0; i < updatedOvertimeRecords.length && remainingRestTime > 0; i++) {
      const overtime = updatedOvertimeRecords[i];
      const deduction = Math.min(overtime.remainingTime, remainingRestTime);
      overtime.remainingTime -= deduction;
      remainingRestTime -= deduction;
      newConsumptions.push({
        id: Date.now() + Math.random(),
        overtimeId: overtime.id,
        restStartTime: startTimestamp,
        restEndTime: endTimestamp,
        deductionTime: deduction,
        consumptionStartTime: overtime.startTime,
        consumptionEndTime: overtime.startTime + deduction * 60 * 60 * 1000,
        type: "调休"
      });
    }

    // 如果还有剩余时间，使用年假
    if (remainingRestTime > 0) {
      annualLeaveUsed = Math.min(remainingRestTime, annualLeave.remainingHours);
      remainingRestTime -= annualLeaveUsed;
      setAnnualLeave(prev => ({
        ...prev,
        remainingHours: prev.remainingHours - annualLeaveUsed
      }));
      newConsumptions.push({
        id: Date.now() + Math.random(),
        overtimeId: null,
        restStartTime: startTimestamp,
        restEndTime: endTimestamp,
        deductionTime: annualLeaveUsed,
        consumptionStartTime: startTimestamp + (restDuration - remainingRestTime - annualLeaveUsed) * 60 * 60 * 1000,
        consumptionEndTime: endTimestamp,
        type: "年假"
      });
    }

    if (remainingRestTime > 0) {
      alert("剩余牛马时间和年假时间不足以申请这么摆烂时间！");
      return;
    }

    const newRestRecord = {
      txbegintime: startTimestamp,
      txendtime: endTimestamp,
      id: Date.now(),
      duration: restDuration - annualLeaveUsed,  // 只记录实际使用的摆烂时间
    };

    setRestRecords([...restRecords, newRestRecord]);
    setOvertimeRecords(updatedOvertimeRecords);
    setConsumptionRecords([...consumptionRecords, ...newConsumptions]);

    if (annualLeaveUsed > 0) {
      setAnnualLeaveRecords([...annualLeaveRecords, {
        id: Date.now(),
        startTime: startTimestamp + (restDuration - annualLeaveUsed) * 60 * 60 * 1000,
        endTime: endTimestamp,
        duration: annualLeaveUsed
      }]);
    }
  };

  const calculateDuration = (record) => {
    let startTime, endTime;
    if (record.txbegintime && record.txendtime) {
      startTime = new Date(record.txbegintime);
      endTime = new Date(record.txendtime);
    } else if (record.startTime && record.endTime) {
      startTime = new Date(record.startTime);
      endTime = new Date(record.endTime);
    } else {
      console.error("Invalid record format:", record);
      return 0;
    }
    return (endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);
  };

  const calculateTotalOvertimeHours = () => {
    return overtimeRecords.reduce(
      (total, record) => total + record.duration,
      0
    );
  };

  const calculateTotalRestHours = () => {
    return restRecords.reduce((total, record) => total + record.duration, 0);
  };

  const calculateTotalAvailableTime = () => {
    const totalOvertimeHours = calculateTotalOvertimeHours();
    const totalRestHours = calculateTotalRestHours();
    const availableTime = totalOvertimeHours - totalRestHours + annualLeave.remainingHours;
    return Math.max(availableTime, 0); // 确保返回值不小于0
  };
  const calculateRecAvailableTime = () => {
    const totalOvertimeHours = calculateTotalOvertimeHours();
    const totalRestHours = calculateTotalRestHours();
    const availableTime = totalOvertimeHours - totalRestHours;
    return Math.max(availableTime, 0); // 确保返回值不小于0
  };

  const handleSetAnnualLeave = (days) => {
    const hours = days * 8;
    setAnnualLeave({ days, remainingHours: hours });
  };

  const handleExpand = (record) => {
    setExpandedRecord(record);
  };

  const handleCollapse = () => {
    setExpandedRecord(null);
  };

  const handleDelete = (record) => {
    if (window.confirm("确定要删除这条记录吗？")) {
      const updatedOvertimeRecords = overtimeRecords.filter(
        (item) => item.id !== record.id
      );
      setOvertimeRecords(updatedOvertimeRecords);
    }
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setShowEditModal(true);
  };

  const handleEditSubmit = (updatedRecord) => {
    if (updatedRecord.txbegintime) {
      // 处理摆烂记录的编辑
      handleRestEditSubmit(updatedRecord);
    } else if (updatedRecord.startTime && updatedRecord.endTime) {
      if (updatedRecord.type === "年假") {
        // 处理年假记录的编辑
        const startTimestamp = new Date(updatedRecord.startTime).getTime();
        const endTimestamp = new Date(updatedRecord.endTime).getTime();
        const newDuration = (endTimestamp - startTimestamp) / (1000 * 60 * 60);
        const durationDifference = newDuration - updatedRecord.duration;

        setAnnualLeaveRecords(prevRecords =>
          prevRecords.map(record =>
            record.id === updatedRecord.id
              ? { ...updatedRecord, duration: newDuration }
              : record
          )
        );

        setAnnualLeave(prev => ({
          ...prev,
          remainingHours: prev.remainingHours - durationDifference
        }));
      } else {
        // 处理牛马记录的编辑
        const startTime = new Date(updatedRecord.startTime).getTime();
        const endTime = new Date(updatedRecord.endTime).getTime();
        const duration = (endTime - startTime) / (1000 * 60 * 60);
        const updatedOvertimeRecords = overtimeRecords.map((record) => {
          if (record.id === editRecord.id) {
            return {
              ...updatedRecord,
              startTime: startTime,
              endTime: endTime,
              duration: duration,
              remainingTime: duration,
            };
          }
          return record;
        });
        setOvertimeRecords(updatedOvertimeRecords);
      }
    }
    setShowEditModal(false);
  };

  const handleEditCancel = () => {
    setShowEditModal(false);
  };

  const handleSaveData = () => {
    const data = {
      overtimeRecords,
      consumptionRecords,
      restRecords,
      annualLeave,
      annualLeaveRecords
    };

    const jsonString = JSON.stringify(data);
    const encryptedData = encryptRC4(jsonString, "Legna");
    const blob = new Blob([encryptedData], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "overtime_data.牛马";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleLoadData = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const encryptedData = e.target.result;
          const decryptedData = decryptRC4(encryptedData, "Legna");
          const importedData = JSON.parse(decryptedData);

          const convertedData = {
            overtimeRecords: importedData.overtimeRecords,
            consumptionRecords: importedData.consumptionRecords,
            restRecords: importedData.restRecords,
            annualLeave: importedData.annualLeave,
            annualLeaveRecords: importedData.annualLeaveRecords
          };

          const localStorageData = {
            overtimeRecords: JSON.parse(localStorage.getItem("overtimeRecords") || "[]"),
            consumptionRecords: JSON.parse(localStorage.getItem("consumptionRecords") || "[]"),
            restRecords: JSON.parse(localStorage.getItem("restRecords") || "[]"),
            annualLeave: JSON.parse(localStorage.getItem("annualLeave") || "{}"),
            annualLeaveRecords: JSON.parse(localStorage.getItem("annualLeaveRecords") || "[]")
          };

          const isLocalStorageNewer =
            localStorageData.overtimeRecords.length > convertedData.overtimeRecords.length ||
            localStorageData.consumptionRecords.length > convertedData.consumptionRecords.length ||
            localStorageData.restRecords.length > convertedData.restRecords.length ||
            localStorageData.annualLeave.remainingHours > convertedData.annualLeave.remainingHours ||
            localStorageData.annualLeaveRecords.length > convertedData.annualLeaveRecords.length;

          if (isLocalStorageNewer) {
            if (window.confirm("导入的数据比本地存储的数据旧。是否仍要覆盖本地数据？")) {
              updateData(convertedData);
            }
          } else {
            updateData(convertedData);
          }
        } catch (error) {
          console.error("Error parsing imported data:", error);
          alert("无法解析导入的文件。请确保它是有效的密格式。");
        }
      };
      reader.readAsText(file);
    }
  };

  const updateData = (data) => {
    setOvertimeRecords(data.overtimeRecords);
    setConsumptionRecords(data.consumptionRecords);
    setRestRecords(data.restRecords);
    setAnnualLeave(data.annualLeave);
    setAnnualLeaveRecords(data.annualLeaveRecords);
    localStorage.setItem(
      "overtimeRecords",
      JSON.stringify(data.overtimeRecords)
    );
    localStorage.setItem(
      "consumptionRecords",
      JSON.stringify(data.consumptionRecords)
    );
    localStorage.setItem("restRecords", JSON.stringify(data.restRecords));
    localStorage.setItem("annualLeave", JSON.stringify(data.annualLeave));
    localStorage.setItem("annualLeaveRecords", JSON.stringify(data.annualLeaveRecords));
    alert("数据已成功导入并更新。");
  };

  const handleRestDelete = (record) => {
    if (window.confirm("确定要删除这条摆烂记录吗？")) {
      const updatedRestRecords = restRecords.filter(
        (item) => item.id !== record.id
      );
      const updatedConsumptionRecords = consumptionRecords.filter(
        (item) => item.restStartTime !== record.txbegintime || item.restEndTime !== record.txendtime
      );
      const updatedOvertimeRecords = overtimeRecords.map(overtime => {
        const relatedConsumptions = consumptionRecords.filter(
          consumption => consumption.overtimeId === overtime.id &&
            consumption.restStartTime === record.txbegintime &&
            consumption.restEndTime === record.txendtime
        );
        const restoredTime = relatedConsumptions.reduce((total, consumption) => total + consumption.deductionTime, 0);
        return {
          ...overtime,
          remainingTime: overtime.remainingTime + restoredTime
        };
      });

      setRestRecords(updatedRestRecords);
      setConsumptionRecords(updatedConsumptionRecords);
      setOvertimeRecords(updatedOvertimeRecords);
    }
  };

  const handleRestEdit = (record) => {
    setEditRecord(record);
    setShowEditModal(true);
  };

  const handleRestEditSubmit = (updatedRecord) => {
    const startTimestamp = new Date(updatedRecord.txbegintime).getTime();
    const endTimestamp = new Date(updatedRecord.txendtime).getTime();
    const newDuration = calculateWorkingHours(startTimestamp, endTimestamp);
    const durationDifference = newDuration - updatedRecord.duration;

    if (durationDifference > 0) {
      const totalAvailableOvertime = overtimeRecords.reduce(
        (total, record) => total + record.remainingTime,
        0
      );
      if (durationDifference > totalAvailableOvertime) {
        alert("剩余牛马时间不足以增加这么多调时间！");
        return;
      }
    }

    const updatedRestRecords = restRecords.map((record) => {
      if (record.id === editRecord.id) {
        return {
          ...updatedRecord,
          txbegintime: startTimestamp,
          txendtime: endTimestamp,
          duration: newDuration,
        };
      }
      return record;
    });

    let updatedOvertimeRecords = [...overtimeRecords];
    let updatedConsumptionRecords = [...consumptionRecords];

    if (durationDifference !== 0) {
      const { updatedOvertime, newConsumptions } = adjustConsumptions(
        updatedOvertimeRecords,
        updatedConsumptionRecords,
        updatedRecord,
        durationDifference
      );
      updatedOvertimeRecords = updatedOvertime;
      updatedConsumptionRecords = newConsumptions;
    }

    setRestRecords(updatedRestRecords);
    setOvertimeRecords(updatedOvertimeRecords);
    setConsumptionRecords(updatedConsumptionRecords);
    setShowEditModal(false);
  };

  const adjustConsumptions = (overtimeRecords, consumptionRecords, updatedRest, durationDifference) => {
    let remainingAdjustment = durationDifference;
    const newConsumptions = [...consumptionRecords];
    const updatedOvertime = overtimeRecords.map(overtime => {
      const relatedConsumptions = newConsumptions.filter(
        consumption => consumption.overtimeId === overtime.id &&
          consumption.restStartTime === updatedRest.txbegintime &&
          consumption.restEndTime === updatedRest.txendtime
      );
      
      let updatedOvertime = { ...overtime };
      
      for (let consumption of relatedConsumptions) {
        if (remainingAdjustment === 0) break;
        
        const index = newConsumptions.findIndex(c => c.id === consumption.id);
        let adjustedConsumption = { ...consumption };
        
        if (remainingAdjustment > 0) {
          const increase = Math.min(remainingAdjustment, updatedOvertime.remainingTime);
          adjustedConsumption.deductionTime += increase;
          adjustedConsumption.consumptionEndTime += increase * 60 * 60 * 1000;
          updatedOvertime.remainingTime -= increase;
          remainingAdjustment -= increase;
        } else {
          const decrease = Math.min(-remainingAdjustment, consumption.deductionTime);
          adjustedConsumption.deductionTime -= decrease;
          adjustedConsumption.consumptionEndTime -= decrease * 60 * 60 * 1000;
          updatedOvertime.remainingTime += decrease;
          remainingAdjustment += decrease;
        }
        
        newConsumptions[index] = adjustedConsumption;
      }
      
      return updatedOvertime;
    });
    
    return { updatedOvertime, newConsumptions };
  };

  const handleAnnualLeaveEdit = (record) => {
    setEditRecord(record);
    setShowEditModal(true);
  };

  const handleAnnualLeaveDelete = (record) => {
    if (window.confirm("确定要删除这条年假记录吗？")) {
      // 删除年假记录
      setAnnualLeaveRecords(annualLeaveRecords.filter(r => r.id !== record.id));

      // 归还年假时间
      setAnnualLeave(prev => ({
        ...prev,
        remainingHours: prev.remainingHours + record.duration
      }));

      // 找到与这条年假记录相关的消耗记录
      const relatedConsumptions = consumptionRecords.filter(
        c => c.restStartTime === record.startTime && c.restEndTime === record.endTime && c.type === "年假"
      );

      // 更新加班记录、消耗记录和摆烂记录
      let updatedOvertimeRecords = [...overtimeRecords];
      let updatedConsumptionRecords = [...consumptionRecords];
      let updatedRestRecords = [...restRecords];

      relatedConsumptions.forEach(consumption => {
        // 从消耗记录中移除这条记录
        updatedConsumptionRecords = updatedConsumptionRecords.filter(c => c.id !== consumption.id);

        // 找到对应的摆烂记录并更新
        const restIndex = updatedRestRecords.findIndex(r => 
          r.txbegintime === consumption.restStartTime && r.txendtime === consumption.restEndTime
        );
        if (restIndex !== -1) {
          // 减少摆烂记录的总时长
          updatedRestRecords[restIndex].duration -= consumption.deductionTime;
          // 如果摆烂记录的时长变为0或负数，则删除该记录
          if (updatedRestRecords[restIndex].duration <= 0) {
            updatedRestRecords.splice(restIndex, 1);
          }
        }
      });

      // 更新状态
      setOvertimeRecords(updatedOvertimeRecords);
      setConsumptionRecords(updatedConsumptionRecords);
      setRestRecords(updatedRestRecords);

      alert("年假记录已删除，相关时间已归还，摆烂记录已更新。");
    }
  };

  return (
    <div className="App">
      <h1>牛马摆烂工具</h1>
      <div className="update-log">
        <button onClick={() => setShowUpdateLog(!showUpdateLog)} className="update-log-toggle">
          {showUpdateLog ? "隐藏更新记录" : "显示更新记录"}
        </button>
        {showUpdateLog && (
          <div className="update-log-content">
            <h3>更新记录</h3>
            <ul>
              {updateLog.map((log, index) => (
                <li key={index}>
                  <strong>{log.date}:</strong> {log.content}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="titbitun">
  <button className="big-inbutton" onClick={handleSaveData}>保存数据到本地</button>
  <input
    type="file"
    ref={fileInputRef}
    style={{ display: "none" }}
    onChange={handleLoadData}
    accept=".牛马"
  />
  <button className="big-inbutton" style={{ marginLeft: "10px" }} onClick={() => fileInputRef.current.click()}>加载备份数据</button>
</div>
      <div>
        <h2>
          本年度您已经牛马了 <strong><font color="red">{calculateTotalOvertimeHours().toFixed(2)}</font></strong> 小时，
          剩余可摆烂时间 <strong><font color="red">{calculateRecAvailableTime().toFixed(2)}</font></strong>+<strong><font color="blue">{(annualLeave.remainingHours).toFixed(2)}</font></strong>小时
        </h2>
        <h2>
          年假时间：<strong><font color="blue">{annualLeave.days}</font></strong> 天，（<strong><font color="blue">{(annualLeave.remainingHours).toFixed(2)}</font></strong> 小时）
        </h2>
        <h2>
          年度您已经摆烂了 <strong><font color="red">{calculateTotalRestHours().toFixed(2)}</font></strong> 小时，
          折合工作日 <strong><font color="red">{(calculateTotalRestHours() / 8).toFixed(2)}</font></strong> 天
        </h2>

        <div>
          <h3>设置年假</h3>
          <label>
            <input 
              type="radio" 
              name="annualLeave" 
              onChange={() => handleSetAnnualLeave(0)} 
              checked={annualLeave.days === 0} 
            /> 无年假
          </label>
          <label>
            <input 
              type="radio" 
              name="annualLeave" 
              onChange={() => handleSetAnnualLeave(5)} 
              checked={annualLeave.days === 5} 
            /> 5天
          </label>
          <label>
            <input 
              type="radio" 
              name="annualLeave" 
              onChange={() => handleSetAnnualLeave(10)} 
              checked={annualLeave.days === 10} 
            /> 10天
          </label>
          <label>
            <input 
              type="radio" 
              name="annualLeave" 
              onChange={() => setAnnualLeave({...annualLeave, days: null})}
              checked={![0, 5, 10].includes(annualLeave.days)} 
            /> 其他
            <input
              type="number"
              min="0"
              value={annualLeave.days === null ? '' : annualLeave.days}
              onChange={(e) => {
                const days = e.target.value === '' ? null : Number(e.target.value);
                setAnnualLeave({...annualLeave, days: days});
              }}
              disabled={[0, 5, 10].includes(annualLeave.days)}
            />
          </label>
          <button className="big-button" onClick={() => {
            if (annualLeave.days !== null) {
              handleSetAnnualLeave(annualLeave.days);
            } else {
              alert('请输入有效的年假天数');
            }
          }}>  设置年假</button>
        </div>
      </div>
      <OvertimeRecordForm onRecord={handleOvertimeRecord} />
      <RestRecordForm onRecord={handleRestRecord} />
      <RecordList
        overtimeRecords={overtimeRecords}
        restRecords={restRecords}
        consumptionRecords={consumptionRecords}
        annualLeaveRecords={annualLeaveRecords}
        expandedRecord={expandedRecord}
        onExpand={handleExpand}
        onCollapse={handleCollapse}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onRestDelete={handleRestDelete}
        onRestEdit={handleRestEdit}
        showEditModal={showEditModal}
        editRecord={editRecord}
        setEditRecord={setEditRecord}
        onEditSubmit={handleEditSubmit}
        onRestEditSubmit={handleRestEditSubmit}
        onEditCancel={handleEditCancel}
        onAnnualLeaveDelete={handleAnnualLeaveDelete}
        onAnnualLeaveEdit={handleAnnualLeaveEdit}
      />
    </div>
  );
}

function OvertimeRecordForm({ onRecord }) {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!startTime || !endTime) {
      alert("请输入牛马的开始时间和结束时间！");
      return;
    }
    const startTimestamp = new Date(startTime).getTime(); // 直接使用本地时间
    const endTimestamp = new Date(endTime).getTime(); // 直接使用本地时间
    if (endTimestamp <= startTimestamp) {
      alert("牛马结束时间必须大于开始时间！");
      return;
    }
    onRecord({
      startTime: startTime,
      endTime: endTime,
    });
    setStartTime("");
    setEndTime("");
  };

  return (
    <form onSubmit={handleSubmit} className="form-inline">
      <div className="form-group">
        <h3>牛马开始时间</h3>
        <input
          type="datetime-local"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
      </div>
      <div className="form-group">
        <h3>牛马结束时间</h3>
        <input
          type="datetime-local"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          min={startTime}
        />
      </div>
      <button className="big-inbutton" type="submit">录入</button>
    </form>
  );
}

function RestRecordForm({ onRecord }) {
  const [txbegintime, setTxbegintime] = useState("");
  const [txendtime, setTxendtime] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!txbegintime || !txendtime) {
      alert("请输入摆烂开始时间和结束时间！");
      return;
    }
    const beginTimestamp = new Date(txbegintime).getTime(); // 直使用本地时间
    const endTimestamp = new Date(txendtime).getTime(); // 直接使用本地时间
    if (endTimestamp <= beginTimestamp) {
      alert("摆烂结束时间必须大于开始时间！");
      return;
    }
    onRecord({
      txbegintime: txbegintime,
      txendtime: txendtime,
    });
    setTxbegintime("");
    setTxendtime("");
  };

  return (
    <form onSubmit={handleSubmit} className="form-inline">
      <div className="form-group">
        <h3>摆烂开始时</h3>
        <input
          type="datetime-local"
          value={txbegintime}
          onChange={(e) => setTxbegintime(e.target.value)}
        />
      </div>
      <div className="form-group">
        <h3>摆烂结束时间</h3>
        <input
          type="datetime-local"
          value={txendtime}
          onChange={(e) => setTxendtime(e.target.value)}
          min={txbegintime}
        />
      </div>
      <button className="big-inbutton"  type="submit">录入</button>
    </form>
  );
}

function RecordList({
  overtimeRecords,
  restRecords,
  consumptionRecords,
  annualLeaveRecords,
  expandedRecord,
  onExpand,
  onCollapse,
  onDelete,
  onEdit,
  showEditModal,
  editRecord,
  setEditRecord,
  onEditSubmit,
  onEditCancel,
  onRestDelete,
  onRestEdit,
  onRestEditSubmit,
  onAnnualLeaveDelete,
  onAnnualLeaveEdit,
}) {
  const [overtimePage, setOvertimePage] = useState(1);
  const [restPage, setRestPage] = useState(1);
  const [annualLeavePage, setAnnualLeavePage] = useState(1);
  const recordsPerPage = 10;

  const paginateRecords = (records, page) => {
    const startIndex = (page - 1) * recordsPerPage;
    return records.slice(startIndex, startIndex + recordsPerPage);
  };

  const overtimePageCount = Math.ceil(overtimeRecords.length / recordsPerPage);
  const restPageCount = Math.ceil(restRecords.length / recordsPerPage);
  const annualLeavePageCount = Math.ceil(annualLeaveRecords.length / recordsPerPage);

  return (
    <div>
      <h3>牛马记录</h3>
      <table>
        <thead>
          <tr>
            <th>详细记录</th>
            <th>序号</th>
            <th>类型</th>
            <th>开始时间</th>
            <th>结束时间</th>
            <th>总时长</th>
            <th>剩余可摆烂时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {paginateRecords(overtimeRecords, overtimePage).map(
            (record, index) => (
              <React.Fragment key={record.id}>
                <tr className={expandedRecord === record ? "expanded" : ""}>
                  <td>
                    {expandedRecord === record ? (
                      <button className="big-button" onClick={onCollapse}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M36 18L24 30L12 18"
                            stroke="#000000"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    ) : (
                      <button className="big-button" onClick={() => onExpand(record)}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 12L31 24L19 36"
                            stroke="#000000"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                  </td>
                  <td>{(overtimePage - 1) * recordsPerPage + index + 1}</td>
                  <td><span className="tag annual-leave">牛马</span></td>
                  <td>{formatDateTime(record.startTime)}</td>
                  <td>{formatDateTime(record.endTime)}</td>
                  <td>{record.duration.toFixed(2)} 小时</td>
                  <td>{record.remainingTime.toFixed(2)} 小时</td>
                  <td>
                    <button className="delete-button" onClick={() => onDelete(record)}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 10V44H39V10H9Z"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 20V33"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M28 20V33"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 10H44"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16 10L19.289 4H28.7771L32 10H16Z"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <p></p>
                    <button className="big-button" onClick={() => onEdit(record)}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 42H43"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11 26.7199V34H18.3172L39 13.3081L31.6951 6L11 26.7199Z"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
                {expandedRecord === record && (
                  <tr className="rest-consumption">
                    <td colSpan="8">
                      <h4>摆烂消耗记录</h4>
                      <table>
                        <thead>
                          <tr>
                            <th>序号</th>
                            <th>类型</th>
                            <th>摆烂申请开始时间</th>
                            <th>摆烂申请结束时间</th>
                            <th>实际消耗开始时间</th>
                            <th>实际消耗结束时间</th>
                            <th>摆烂扣除时间</th>
                          </tr>
                        </thead>
                        <tbody>
                          {consumptionRecords
                            .filter(
                              (consumption) =>
                                consumption.overtimeId === record.id
                            )
                            .map((consumption, idx) => (
                              <tr key={consumption.id}>
                                <td>{idx + 1}</td>
                                <td>
                                  <span className={`tag ${consumption.type === "年假" ? "annual-leave" : " comp-leave"}`}>
                                    {consumption.type === "年假" ? "年假" : "摆烂"}
                                  </span>
                                </td>
                                <td>{formatDateTime(consumption.restStartTime)}</td>
                                <td>{formatDateTime(consumption.restEndTime)}</td>
                                <td>{formatDateTime(consumption.consumptionStartTime)}</td>
                                <td>{formatDateTime(consumption.consumptionEndTime)}</td>
                                <td>{consumption.deductionTime.toFixed(2)} 小时</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          )}
        </tbody>
      </table>
      <div className="pagination">
        <button className="big-button"
          onClick={() => setOvertimePage(Math.max(1, overtimePage - 1))}
          disabled={overtimePage === 1}
        >
          上一页
        </button>
        <span className="page">
          {overtimePage} / {overtimePageCount}
        </span >
        <button className="big-button"
          onClick={() =>
            setOvertimePage(Math.min(overtimePageCount, overtimePage + 1))
          }
          disabled={overtimePage === overtimePageCount}
        >
          下一页
        </button>
      </div>

      <h3>摆烂记录</h3>
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th>类型</th>
            <th>申请摆烂开始时间</th>
            <th>申请摆烂结束时间</th>
            <th>总时长</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {paginateRecords(restRecords, restPage).map((record, index) => (
            <tr key={record.id}>
              <td>{(restPage - 1) * recordsPerPage + index + 1}</td>
              <td><span className="tag comp-leave">摆烂</span></td>
              <td>{formatDateTime(record.txbegintime)}</td>
              <td>{formatDateTime(record.txendtime)}</td>
              <td>{record.duration.toFixed(2)} 小时</td>
              <td>
                <button className="delete-button" onClick={() => onRestDelete(record)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 10V44H39V10H9Z"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="4"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20 20V33"
                      stroke="#000000"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M28 20V33"
                      stroke="#000000"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 10H44"
                      stroke="#000000"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 10L19.289 4H28.7771L32 10H16Z"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="4"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <p></p>
                <button className="big-button" onClick={() => onRestEdit(record)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 42H43"
                      stroke="#000000"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11 26.7199V34H18.3172L39 13.3081L31.6951 6L11 26.7199Z"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="4"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button className="big-button"
          onClick={() => setRestPage(Math.max(1, restPage - 1))}
          disabled={restPage === 1}
        >
          上一页
        </button>
        <span className="page">
          {restPage} / {restPageCount}
        </span>
        <button className="big-button"
          onClick={() => setRestPage(Math.min(restPageCount, restPage + 1))}
          disabled={restPage === restPageCount}
        >
          下一页
        </button>
      </div>

      <h3>年假记录</h3>
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th>类型</th>
            <th>年假开始时间</th>
            <th>年假结束时间</th>
            <th>总时长</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {paginateRecords(annualLeaveRecords, annualLeavePage).map((record, index) => (
            <tr key={record.id}>
              <td>{(annualLeavePage - 1) * recordsPerPage + index + 1}</td>
              <td><span className="tag annual-leave">年假</span></td>
              <td>{formatDateTime(record.startTime)}</td>
              <td>{formatDateTime(record.endTime)}</td>
              <td>{record.duration.toFixed(2)} 小时</td>
              <td>
                {/* <button className="big-button" onClick={() => onAnnualLeaveEdit(record)}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 42H43"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11 26.7199V34H18.3172L39 13.3081L31.6951 6L11 26.7199Z"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <p></p> */}
                <button className="delete-button" onClick={() => onAnnualLeaveDelete(record)}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 10V44H39V10H9Z"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 20V33"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M28 20V33"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 10H44"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16 10L19.289 4H28.7771L32 10H16Z"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="4"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    
                    
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button className="big-button"
          onClick={() => setAnnualLeavePage(Math.max(1, annualLeavePage - 1))}
          disabled={annualLeavePage === 1}
        >
          上一页 
        </button>
        <span className="page">
           {annualLeavePage} / {annualLeavePageCount} 
        </span>
        <button className="big-button"
          onClick={() => setAnnualLeavePage(Math.min(annualLeavePageCount, annualLeavePage + 1))}
          disabled={annualLeavePage === annualLeavePageCount}
        >
          下一页 
        </button>
      </div>

      {showEditModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>
              {editRecord.txbegintime
                ? "修改摆烂记录"
                : editRecord.type === "年假"
                ? "修改年假记录"
                : "修改牛马记录"}
            </h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onEditSubmit(editRecord);
              }}
            >
              <label>
                {editRecord.txbegintime
                  ? "开始时间:"
                  : editRecord.type === "年假"
                  ? "年假开始时间:"
                  : "牛马开始时间:"}
                <input
                  type="datetime-local"
                  value={timestampToDateString(
                    editRecord.txbegintime || editRecord.startTime
                  )}
                  onChange={(e) =>
                    setEditRecord({
                      ...editRecord,
                      [editRecord.txbegintime ? "txbegintime" : "startTime"]: dateStringToTimestamp(
                        e.target.value
                      ),
                    })
                  }
                />
              </label>
              <label>
                {editRecord.txendtime
                  ? "结束时间:"
                  : editRecord.type === "年假"
                  ? "年假结束时间:"
                  : "牛马结束时间:"}
                <input
                  type="datetime-local"
                  value={timestampToDateString(
                    editRecord.txendtime || editRecord.endTime
                  )}
                  onChange={(e) =>
                    setEditRecord({
                      ...editRecord,
                      [editRecord.txendtime ? "txendtime" : "endTime"]: dateStringToTimestamp(
                        e.target.value
                      ),
                    })
                  }
                />
              </label>
              <button className="big-button" type="submit">
                保存
              </button>
              <button className="big-button" type="button" onClick={onEditCancel}>
                取消
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

